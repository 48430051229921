import React from "react";

const LeftModule = () => {
	return (
		<div className="w-1/4 h-60 border-r-2 border-other py-2 px-4 text-right text-string flex flex-col justify-right">
			<div className="font-bold">Nate Young</div>
			<div className="text-xs text-stringTwo">C# / JS Developer</div>
			<div className="text-sm mt-4">
				Self-taught full-stack developer,
				<br />
				always striving to increase my knowledge around web apps and the
				infrastructure behind them.
			</div>
			<div className="text-sm mt-4">
				Over 10 years developing, hosting and maintaining web apps.
			</div>
		</div>
	);
};

export default LeftModule;
