import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

const RightModule = () => {
	return (
		<div className="w-1/4 h-60 relative">
			<img
				alt="code background"
				src="/images/code_img1.png"
				className="gradiantFade relative top-0 h-full"
			/>
			<div className="flex flex-col place-content-between absolute top-0 h-full py-2 px-4 ">
				<img
					src="/images/img1.png"
					alt="avatar"
					className="h-20 w-[3.75rem] mb-4"
				/>
				<div>
					<a href="https://github.com/Araytha">
						<FontAwesomeIcon
							icon={faGithub}
							size="xl"
							className="pr-3 text-string hover:text-stringTwo"
						/>
					</a>
					{/* <a href="#">
						<FontAwesomeIcon
							icon={faLinkedin}
							size="xl"
							className="pr-3 text-string hover:text-stringTwo"
						/>
					</a> */}
				</div>
			</div>
		</div>
	);
};

export default RightModule;
