import React from "react";
import LeftModule from "./LeftModule.tsx";
import RightModule from "./RightModule.tsx";

const MainComponent = () => {
	return (
		<div className="h-screen w-screen bg-greyish flex flex-row justify-center items-center">
			<LeftModule />
			<RightModule />
			<img
				alt="code background"
				src="/images/code_img3.png"
				className="gradiantFade2 absolute bottom-0 left-0 h-[30%]"
			/>
		</div>
	);
};

export default MainComponent;
